import React, { useEffect, useState } from "react";
import { formatDate, formatNumber } from "../../_common/functions";

interface incomingProps {
    interest: any[];
    user_details?: any;
    filterDate: string;
    filterCertificateNumber: string;
}

export default function InterestList({ interest, user_details, filterDate, filterCertificateNumber }: incomingProps) {

    // const total = (interest.reduce((total, currentItem) => total = total + currentItem?.total_interest, 0));
    const [totalAmount, setTotalAmount] = useState<number>(0)
    // console.log({interest})

    useEffect(() => {
        if (filterDate !== '' && filterCertificateNumber !== '') {
            const total = interest.filter((item, i) =>
                (item?.interest_month_year).search(filterDate.slice(0, 7)) >= 0 && (item?.user_certificate_number).search(filterCertificateNumber) >= 0)
                .reduce((total, currentItem) => total = total + currentItem?.total_interest, 0)
            setTotalAmount(total)
        }
        else if (filterDate !== '') {
            const total = interest.filter((item, i) =>
                (item?.interest_month_year).search(filterDate.slice(0, 7)) >= 0)
                .reduce((total, currentItem) => total = total + currentItem?.total_interest, 0)
            setTotalAmount(total)
        }
        else if (filterCertificateNumber !== '') {
            const total = interest.filter((item, i) =>
                (item?.user_certificate_number).search(filterCertificateNumber) >= 0)
                .reduce((total, currentItem) => total = total + currentItem?.total_interest, 0)
            setTotalAmount(total)
        }
        else {
            const total = (interest.reduce((total, currentItem) => total = total + currentItem?.total_interest, 0));
            setTotalAmount(total)
        }
    }, [filterDate, filterCertificateNumber])

    return (
        <React.Fragment>
            {
                interest && interest.length > 0 ?
                    interest.map((item, i) => {
                        if (filterDate !== '' && filterCertificateNumber !== '') {
                            if ((item?.interest_month_year).search(filterDate.slice(0, 7)) >= 0 && (item?.user_certificate_number).search(filterCertificateNumber) >= 0) {
                                return (
                                    <tr key={i}>
                                        <td >{item?.user_certificate_number}</td>
                                        {/* <td>{item?.interest_month_year}</td> */}
                                        <td>R {item?.total_interest}</td>
                                    </tr>
                                )
                            }
                        }
                        else if (filterDate !== '') {
                            if ((item?.interest_month_year).search(filterDate.slice(0, 7)) >= 0) {
                                return (
                                    <tr key={i}>
                                        <td >{item?.user_certificate_number}</td>
                                        {/* <td>{item?.interest_month_year}</td> */}
                                        <td>R {item?.total_interest}</td>
                                    </tr>
                                )
                            }
                        }
                        else if (filterCertificateNumber !== '') {
                            if ((item?.user_certificate_number).search(filterCertificateNumber) >= 0) {
                                return (
                                    <tr key={i}>
                                        <td >{item?.user_certificate_number}</td>
                                        {/* <td>{item?.interest_month_year}</td> */}
                                        <td>R {item?.total_interest}</td>
                                    </tr>
                                )
                            }
                        }
                        else {
                            return (
                                <tr key={i}>
                                    <td >{item?.user_certificate_number}</td>
                                    {/* <td>{item?.interest_month_year}</td> */}
                                    <td>R {item?.total_interest}</td>
                                </tr>
                            )
                        }
                    })
                    :
                    null
            }
            <tr className="total-order">
                {/* <td ></td> */}
                <td ><b>Total Interest</b></td>
                <td><b>R {formatNumber(totalAmount)}</b></td>
            </tr>
        </React.Fragment>
    )
}



import React, { useState, useEffect } from "react";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import NoBenefits from "../components/benefit/noBenefits";
import BenefitList from "../components/benefit/benefitList";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import toast from 'react-hot-toast';

export default function Benefits() {

  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const [allBenifits, setAllBenifits] = useState<any>(null)

  const getbenefit = () => {
    showLoader();
    userApi.getbenefits({}, (message: string, resp: any) => {
      //console.log({ resp })
      hideLoader();
      setAllBenifits(resp?.data?.data);
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  useEffect(() => {
    getbenefit();
  }, [])

  return (
    <React.Fragment>
      <div className="circle-bg"></div>
      <Header />
      <LeftPanel />
      <section className="main-body">
        <div className="right-menu-part">
          <div className="benifit-sec">
            <h2 className="pr-header">Benefits</h2>
            <p className="pr-peragraph description-text">Entry is through registration with one of our account executives or on the web/mobile app platform. A once off member contribution...  </p>
            <div className="row">
              {allBenifits && allBenifits.length > 0 ?
                <BenefitList allBenifits={allBenifits} />
                :
                <NoBenefits />
              }
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
import React, { useEffect, useState } from "react";
import { formatDate, formatNumber } from '../../_common/functions';

interface incomingProps {
    dividened: any[];
    user_details: any;
    filterDate: string;
}

export default function DividenedList({ dividened, user_details, filterDate }: incomingProps) {

    // const total = (dividened.reduce((total, currentItem) => total = total + currentItem?.total_amount, 0));

    const [totalAmount, setTotalAmount] = useState<number>(0)

    useEffect(() => {
        if (filterDate !== '') {
            const total = dividened.filter((item, i) =>
                formatDate(item?.created_at).search(filterDate.toLocaleLowerCase()) >= 0)
                .reduce((total, currentItem) => total = total + currentItem?.total_amount, 0)
            setTotalAmount(total)
        } else {
            const total = (dividened.reduce((total, currentItem) => total = total + currentItem?.total_amount, 0));
            setTotalAmount(total)
        }
    }, [filterDate])

    return (
        <React.Fragment>
            {
                dividened && dividened.length > 0 ?
                    dividened.map((item, i) => {
                        if (filterDate !== '') {
                            if (formatDate(item?.created_at).search(filterDate.toLocaleLowerCase()) >= 0) {
                                return (
                                    <tr key={i}>
                                        <td >{user_details?.first_name} {user_details?.last_name}</td>
                                        <td>{formatDate(item?.created_at)}</td>
                                        <td>R {formatNumber(item?.certificate_amt)}</td>
                                        <td>{formatNumber(item?.total_certificate)}</td>
                                        <td>R {formatNumber(item?.total_amount)}</td>
                                    </tr>
                                )
                            }
                        } else {
                            return (
                                <tr key={i}>
                                    <td >{user_details?.first_name} {user_details?.last_name}</td>
                                    <td>{formatDate(item?.created_at)}</td>
                                    <td>R {formatNumber(item?.certificate_amt)}</td>
                                    <td>{formatNumber(item?.total_certificate)}</td>
                                    <td>R {formatNumber(item?.total_amount)}</td>
                                </tr>
                            )
                        }
                    })
                    :
                    null
            }
            <tr className="total-order">
                <td ></td>
                <td align="center" colSpan={3}><b>Total Dividened</b></td>
                <td><b>R {formatNumber(totalAmount)}</b></td>
            </tr>
        </React.Fragment>
    )
}



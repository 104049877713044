import React from "react";

export default function Nobuyreport() {

    return (
        <React.Fragment>
            <tr>
                <td align="center" colSpan={5}>No records Found</td>
            </tr>
        </React.Fragment>
    )
}



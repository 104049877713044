import { SAGA_ACTIONS } from '../../../../_config';
import { useApiCall } from '../../common/appApiCallHook';
import {
    BlankReq,
} from '../../../interfaces/ApiReqRes'


export function useCommonApi() {

    const callApi = useApiCall();

    const contactUs = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.COMMON.CONTACT_US, data, onSuccess, onError);
    }

    return {
        contactUs: contactUs,
    }
}
import parse from 'html-react-parser';
import { changeString, extractContent } from '../../_common/functions';

interface incomingProps {
    data: any
}

export default function Benefit({ data }: incomingProps) {

    return (
        <div className="col-lg-6 col-md-6 col-12">
            <div className="black-box ">

                <div className="in-blk">
                    {/* <div className="img_blk">
                        <img src={data?.avatar?.original} alt="" />
                    </div> */}
                    <div className="bk-head-box">
                        <h2>{data?.title}</h2>
                    </div>
                    {/* <div className="bk-check-box">
                        <img src="./assets/img/approve.png" alt="#" />
                    </div> */}
                </div>
                <div className="bk-para-box">
                    <div className="pr-peragraph description-text">{changeString(extractContent(data?.description),130)}...</div>
                </div>
            </div>
        </div>
    )
}
import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useUserApi() {

  const callApi = useApiCall()

  const login = (data: LoginReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGIN, data, onSuccess, onError);
  }

  const logout = (data: BlankReq, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.LOGOUT, data, onSuccess, onError);
  }

  const registeruser = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTER, data, onSuccess, onError);
  }

  const registersucessful = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.REGISTRATION_SUCESSFUL, data, onSuccess, onError);
  }

  const profileUpdate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.PROFILE_UPDATE, data, onSuccess, onError);
  }

  const getbenefits = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_BENIFITS, data, onSuccess, onError);
  }

  const avatarUpload = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.AVATAR_UPLOAD, data, onSuccess, onError);
  }

  const getDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.GET_DETAILS, data, onSuccess, onError);
  }

  const forgotPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.FORGOT_PASSWORD, data, onSuccess, onError);
  }

  const resetPassword = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.USER.RESET_PASSWORD, data, onSuccess, onError);
  }

  return {
    login: login,
    registeruser: registeruser,
    logout: logout,
    registersucessful: registersucessful,
    getbenefits: getbenefits,
    profileUpdate: profileUpdate,
    avatarUpload: avatarUpload,
    getDetails: getDetails,
    forgotPassword: forgotPassword,
    resetPassword: resetPassword,
  }
}
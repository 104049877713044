import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Nobuyreport from "../components/reports/nobuyreport";
import { useCompanyApi } from "../_common/hooks/api/CompanyApiHook";
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import parse from 'html-react-parser';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { URLS } from "../_config";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import moment from "moment";
import { formatNumber } from "../_common/functions";
import { BsDownload } from "react-icons/bs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const optionsStyle: any = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top' as const,
            labels: {
                fontColor: "#fff",
                fontSize: 18
            }
        },
        title: {
            display: true,
            text: 'Growth History Graph',
            color: '#fff',
        },
    },
    // scales: {
    //     yAxes: [{
    //         type: 'linear',
    //         ticks: {
    //             fontColor: "#fff",
    //             beginAtZero: true,
    //         }
    //     }],
    //     xAxes: [{
    //         type: 'linear',
    //         ticks: {
    //             fontColor: '#fff',
    //         }
    //     }]
    // }
};


export default function OurgroupCompanyDetails() {

    const companyApi = useCompanyApi();

    const { showLoader, hideLoader } = useAppLoader()
    const [company, setCompany] = useState<any>(null)
    const [companyFinancial, setCompanyFinancial] = useState<any | []>([])
    const [options, setOptions] = useState<any | []>([])
    const [selectedYear, setSelectedYear] = useState<string>('2022-2023')

    const data = {
        labels: companyFinancial.map((data: any) => moment(data.start_date).format("MMMM YYYY")),
        datasets: [
            {
                label: 'Net Profit',
                data: companyFinancial.map((data: any) => data.net_profit),
                borderColor: 'rgb(255, 99, 132)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
            },
            {
                label: 'Total Income',
                data: companyFinancial.map((data: any) => data.total_income),
                borderColor: 'rgb(53, 162, 235)',
                backgroundColor: 'rgba(53, 162, 235, 0.5)',
            },
        ],
    };

    const getOurgroupCompanyDetails = (financialYear: string) => {
        showLoader();
        companyApi.getOurgroupCompanyDetails({
            financial_year: financialYear
        },
            (message: string, resp: any) => {
                hideLoader();
                // console.log({ resp })
                setCompany(resp?.data?.company)
                setCompanyFinancial(resp?.data?.companyFinancial)
            }, (message: string) => {
                hideLoader();
            }
        )
    }

    const generateFinancialYears = () => {
        const startYear = 2010;
        const endYear = new Date().getFullYear();
        const tempOptions = [];
        for (let year = endYear; year >= startYear; year--) {
            const nextYear = year + 1;
            const label = `${year}-${nextYear.toString().substr(-2)}`;
            const value = `${year}-${nextYear.toString()}`;
            tempOptions.push({ value: value, label: label });
        }
        setOptions(tempOptions)
    }

    const handleYearChange = (event: any) => {
        setSelectedYear(event.target.value);
    }

    useEffect(() => {
        if (selectedYear) {
            getOurgroupCompanyDetails(selectedYear)
        }
    }, [selectedYear])

    useEffect(() => {
        generateFinancialYears()
    }, [])


    return (
        <React.Fragment>
            <div className="circle-bg"></div>
            <Header />
            <LeftPanel />

            <section className="main-body">
                <div className="right-menu-part">
                    <div className="dash-body">
                        <div className="container-fluid">
                            <div className="d-flex justify-content-end mb-3 px-2">
                                <Link to={URLS.HOME} className="btn btn-outline-secondary px-5">Back</Link>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-sm-12 col-12">
                                    <div className="black-box ">
                                        <div className="in-blk">
                                            <div className="img_blk">
                                                <img src={company?.avatar?.original} alt="" />
                                            </div>
                                            <div className="bk-head-box" style={{ minHeight: "66px" }}>
                                                <h2>{company?.name}</h2>
                                                <span className="me-2">{companyFinancial && companyFinancial.length > 0 ? `Total Income: R ${formatNumber(companyFinancial[0]?.total_income)}` : ''}</span>
                                                <span>{companyFinancial && companyFinancial.length > 0 ? `Net Profit: R ${formatNumber(companyFinancial[0]?.net_profit)}` : ''}</span>
                                            </div>
                                            <div className="bk-head-box d-flex justify-content-end" style={{ minHeight: "70px" }}>
                                                <select
                                                    value={selectedYear}
                                                    defaultValue={selectedYear}
                                                    onChange={handleYearChange}
                                                    className="form-control w-25 one"
                                                    style={{ appearance: "revert" }}
                                                >
                                                    <option value="">Select a financial year</option>
                                                    {options.map((option: any) => (
                                                        <option key={option?.value} value={option?.value}>{option?.label}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="bk-para-box">
                                            <div className="pr-peragraph description-text">{company && company?.description && parse(company?.description)}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="black-box" style={{ minHeight: "409px" }}>
                                        <h2>Growth History</h2>
                                        <div className="scroll-table custom-scroll">
                                            <table className="table table-text">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">Id</th>
                                                        <th scope="col">Start Date</th>
                                                        <th scope="col">End Date</th>
                                                        <th scope="col">Net Profit</th>
                                                        <th scope="col">Total Income</th>
                                                        <th scope="col">{" "}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {companyFinancial && companyFinancial.length > 0 ?
                                                        companyFinancial.map((item: any, i: number) => (
                                                            <tr key={i}>
                                                                <td>{item?.id}</td>
                                                                <td>{item?.start_date}</td>
                                                                <td>{item?.end_date}</td>
                                                                <td>R {formatNumber(item?.net_profit)}</td>
                                                                <td>R {formatNumber(item?.total_income)}</td>
                                                                <td><a
                                                                    href={item?.avatar[0]?.original}
                                                                    download
                                                                    target={"__blank"}
                                                                ><BsDownload color="#fff"/></a></td>
                                                            </tr>
                                                        ))
                                                        :
                                                        <Nobuyreport />
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="black-box" style={{ minHeight: "409px" }}>
                                        <Line options={optionsStyle} data={data} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment >
    )
}
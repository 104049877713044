import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import BuyCertificateModal from "../components/modals/buyCertificate.modal";
import toast from 'react-hot-toast';
import { useCompanyApi } from "../_common/hooks/api/CompanyApiHook";
import { formatNumber, handleKeyPressNumber } from "../_common/functions";

export default function BuyCertificate() {

    const { showLoader, hideLoader } = useAppLoader();
    const companyApi = useCompanyApi();

    const [showCertificateModal, setShowBuyCertificateModal] = useState<boolean>(false);
    const [certificateInfo, setCertificateInfo] = useState<any>(null)
    const [quantity, setQuantity] = useState<any>("1")

    const closeCreateCase = () => {
        setShowBuyCertificateModal(false)
    }

    const getCompanyCertificate = () => {
        showLoader();
        companyApi.getCompanyCertificate({}, (message: string, resp: any) => {
            // console.log(resp.data.data.certificate_amt);
            hideLoader();
            setCertificateInfo(resp?.data?.data)
        }, (message: string) => {
            toast.error(message)
            hideLoader();
        });
    }

    useEffect(() => {
        getCompanyCertificate();
    }, [])

    return (
        <React.Fragment>
            <BuyCertificateModal
                show={showCertificateModal}
                close={closeCreateCase}
                price={certificateInfo?.certificate_amt}
                quantity={quantity}
            />
            <div className="circle-bg"></div>
            <Header />
            <LeftPanel />
            <section className="main-body">
                <div className="right-menu-part">
                    <div className="dash-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="portfolio-sec h-252">
                                        <h3>One Certificate value</h3>
                                        <h2>R {formatNumber(certificateInfo?.certificate_amt)}
                                            {/* <img src="./assets/img/drop-gr.svg" alt="#" /> */}
                                        </h2>
                                        {/* <h4>+ R {formatNumber(certificateInfo?.financial?.change_amount)}
                                            <span>
                                                <img src="./assets/img/up-arrow.svg" alt="#" />
                                                3.04%
                                            </span>
                                        </h4> */}
                                        <div className="portfolio-btn d-no">
                                            <button type="button" className="btn ">Available Certificate - {certificateInfo?.financial?.remaing}</button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="login-box row mb-4 investor unt-price h-252">
                                        <div className="mb-3 col-sm-9">
                                            <div className="all-unit">
                                                <div className="unit-input">
                                                    <label htmlFor="" className="form-label">Unit Price</label>
                                                    <input type="text" readOnly className="form-control" value={`R ${formatNumber(certificateInfo?.certificate_amt)}`} />
                                                    <span>Min. R {formatNumber(certificateInfo?.certificate_amt)}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mb-3 col-sm-3">
                                            <label htmlFor="" className="form-label">Qty </label>
                                            <input
                                                className="form-select form-control"
                                                defaultValue={quantity}
                                                onChange={(e: any) => { setQuantity(e.target.value) }}
                                                onKeyPress={(e: any) => handleKeyPressNumber(e)}
                                            />
                                        </div>

                                        <div className="mb-3 col-sm-12">
                                            <div className="portfolio-btn">
                                                <Button className="btn " onClick={() => { setShowBuyCertificateModal(true) }}>Buy Now</Button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { URLS } from "../_config";

export default function Profile() {

    const user: any = useUserSelector(); console.log({ user })
    const userApi = useUserApi();

    const { register, reset, watch, setValue } = useForm();
    const avatar = watch('avatar')

    const getDetails = () => {
        userApi.getDetails({
            "username": user.username
        }, (message: string, resp: any) => {
        }, (message: string) => {
        });
    }

    const avatarUpload = (image: any) => {
        const params = new FormData();
        params.append("avatar", image);
        params.append("user_id", user.id);
        return new Promise((resolve: any, reject: any) => {
            userApi.avatarUpload(params, (message: string, resp: any) => {
                resolve(message)
            }, (message: string) => {
                reject(message);
            });
        })

    }

    const avatarUploadNotification = (data: any) => {
        toast(
            (t) => (
                <span>
                    <b>Please note!</b>
                    <br />
                    Do you want to change your profile pic
                    <br />
                    <img src={URL.createObjectURL(data)} alt="" />
                    <hr />
                    <div className="d-flex align-items-center justify-content-end">
                        <button
                            className="btn btn-danger btn-sm m-1"
                            onClick={() => {
                                toast.dismiss(t.id);
                                toast.promise(
                                    avatarUpload(data),
                                    {
                                        loading: "working on request...",
                                        success: (msg: any) => {setValue("avatar", null); getDetails(); return <b>{user.full_name} your avatar uploaded successfuly!</b> },
                                        error: (msg) => { setValue("avatar", null); return <b>{msg}</b> },
                                    },
                                    { duration: 4000 }
                                );
                            }}
                        >
                            Submit
                        </button>

                        <button
                            className="btn btn-secondary btn-sm m-1"
                            onClick={() => { toast.dismiss(t.id); setValue("avatar", null); }}
                        >
                            Cancel
                        </button>
                    </div>
                </span>
            ),
            { duration: 15000, className: 'withdraw-toast' }
        );
    }

    useEffect(() => {
        if (avatar && avatar.length > 0) {
            console.log({ avatar, cd: avatar[0] })
            avatarUploadNotification(avatar[0])
        }
    }, [avatar])

    useEffect(() => {
        getDetails()
    }, [])

    return (
        <React.Fragment>
            <div className="circle-bg"></div>
            <Header />
            <LeftPanel />
            <section className="main-body">
                <div className="right-menu-part">
                    <div className="dash-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="portfolio-sec h-252">
                                        <h1>Personal Details</h1>
                                        <h3 className="fs-1">Name <span className="fs-2">{user?.full_name}</span></h3>
                                        <h3 className="fs-4">Mobile <span className="fs-5">{user?.phone}</span></h3>
                                        <h3 className="fs-4">Email <span className="fs-5">{user?.email}</span></h3>
                                        <h3 className="fs-4">DOB <span className="fs-5">{user?.dob}</span></h3>
                                        <Link to={URLS.PROFILE_UPDATE} className="btn btn-primary my-3 px-5">Edit</Link>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-sm-12 col-12">
                                    <div className="portfolio-sec h-252">
                                        <h1>Profile Image</h1>
                                        <div className="avatar mb-4 me-auto ms-auto w-50">
                                            <img src={user?.avatar?.original} alt="" style={{maxHeight: "238px"}} />
                                        </div>
                                        <div className="d-flex justify-content-center gap-3 align-items-center">
                                            <label htmlFor="image">Upload profile pic</label>
                                            <input type="file" id="image" accept="image/*" {...register("avatar", { required: false })} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}
import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useCompanyApi() {

  const callApi = useApiCall()

  const getCompanyCertificate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMPANY.GET_COMPANY_CERTIFICATE, data, onSuccess, onError);
  }

  const getCompanyList = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMPANY.GET_COMPANY_LIST, data, onSuccess, onError);
  }

  const getCompanyDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMPANY.COMPANY_DETAILS, data, onSuccess, onError);
  }

  const getOurgroupCompanyDetails = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.COMPANY.OURGROUP_COMPANY_DETAILS, data, onSuccess, onError);
  }

  return {
    getCompanyCertificate: getCompanyCertificate,
    getCompanyList: getCompanyList,
    getCompanyDetails: getCompanyDetails,
    getOurgroupCompanyDetails: getOurgroupCompanyDetails,
  }
}
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import toast from 'react-hot-toast';

export default function RegistrationSuccessful() {

  const { showLoader, hideLoader } = useAppLoader();
  const userApi = useUserApi();
  const id = useParams().id;
  // console.log("Get id", id);

  const regSucess = (data: any) => {
    let params: any = {
      "remember_token": data,
    }
    // console.log('paramsvalue=>', params);

    showLoader();
    userApi.registersucessful(params, (message: string, resp: any) => {
      // console.log({ resp })
      hideLoader();
      if (resp.status > 200) {
        toast.error(resp.data.data.errors)
      } else {
        toast.success(resp.message);
      }
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  useEffect(() => {
    if(id){
      regSucess(id);
    }
  }, [id])

  return (
    <React.Fragment>
      <section className="main-body">
        <div className="circle-bg"></div>
        <div className="thank-you">
          <img src="../assets/img/thank-you.png" alt="#" />
          <div className="welcome-text mt-5">
            <h2>Thank <span>You</span></h2>
            <p>Please check you mail to verify your account</p>
            <Link to="/login" className="btn btn-primary">Log In</Link>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
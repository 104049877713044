import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppLoader } from '../_common/hooks/common/appLoaderHook';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import toast from 'react-hot-toast';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from 'react-bootstrap';
import '../styles/signup.scss';
import { Controller, useForm } from "react-hook-form";
import { URLS } from "../_config";

export default function SignUp() {

  const userApi = useUserApi();
  const navigate = useNavigate();

  const { showLoader, hideLoader } = useAppLoader();
  const { register, handleSubmit, formState: { errors }, control, getValues } = useForm();

  const [dobError, setDobError] = useState(false);

  const onSubmit = (data: any) => {
    const dobDate = new Date(data.dob);
    if (dobDate > new Date()) {
      setDobError(true);
    } else {
      setDobError(false);
      let params: any = {
        "first_name": data.first_name,
        "last_name": data.last_name,
        "email": data.email,
        'phone': data.phone,
        'dob': data.dob,
        'password': data.password,
        'c_password': data.c_password
      }
      showLoader();
      userApi.registeruser(params, (message: string, resp: any) => {
        // console.log({ resp })
        hideLoader();
        if (resp.status > 200) {
          toast.error(resp.data.data.errors)
        } else {
          toast.success("verification mail is sent to your email address");
          // navigate(URLS.REGISTRATION_SUCESSFUL)
          navigate(URLS.LOGIN)
        }
      }, (message: string) => {
        toast.error(message)
        hideLoader();
      });
    }
  }

  console.log({ errors })

  return (
    <React.Fragment>
      <section className="main-body">
        <div className="circle-bg"></div>
        <div className="two-division wide-division">
          <div className="left-part">
            <img src="./assets/img/reg.png" alt="" />
          </div>
          <div className="right-part">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="login-box-wrap scrollable-left">
                <h1 className="logo-wrap"><img src="./assets/img/logo.png" alt="" /></h1>
                <div className="welcome-text">
                  <h2>Personal <span>Details!</span></h2>
                  <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium</p>
                </div>
                <div className="login-box row mb-4">
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">First Name <span>*</span></label>
                    <input type="text" className="form-control" placeholder="Type First Name" {...register("first_name", { required: true })} />
                    {errors?.first_name ? <div className="invalid-feedback">
                      {errors.first_name.type === "required" ? 'Required' : null}
                    </div> : null}
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">Last Name <span>*</span></label>
                    <input type="text" className="form-control" placeholder="Type Last Name" {...register("last_name", { required: true })} />
                    {errors?.last_name ? <div className="invalid-feedback">
                      {errors.last_name.type === "required" ? 'Required' : null}
                    </div> : null}
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">Email <span>*</span></label>
                    <input type="text" className="form-control" placeholder="Type Email" autoComplete="off" {...register("email", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                    {errors?.email ? <div className="invalid-feedback">
                      {errors.email.type === "required" ? 'Required' : null}
                      {errors.email.type === "pattern" ? 'Invalid email address' : null}
                    </div> : null}
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">Phone <span>*</span></label>
                    <input type="text" className="form-control" placeholder="Type Phone" {...register("phone", { required: true, maxLength: 10, minLength: 10 })} />
                    {errors?.phone ? <div className="invalid-feedback">
                      {errors.phone.type === "required" ? 'Required' : null}
                      {errors.phone.type === "maxLength" ? 'phone number must be of 10 digit' : null}
                      {errors.phone.type === "minLength" ? 'phone number must be of 10 digit' : null}
                    </div> : null}
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">Date of Birth <span>*</span></label>
                    {/* <Controller
                      control={control} name="dob"
                      rules={{ required: true }}
                      render={({ field: { value, onChange, onBlur } }) => (
                        <DatePicker
                          dateFormat="yyyy-MM-dd"
                          selected={value}
                          className="form-control"
                          minDate={subYears(new Date(), 100)}
                          maxDate={new Date()}
                          onChange={onChange}
                          showYearDropdown
                        />
                      )}
                    /> */}
                    <input type="date" className="form-control" placeholder="DOB" {...register("dob", { required: true })} max={new Date().toISOString().split("T")[0]} />
                    {errors?.dob ? <div className="invalid-feedback">
                      {errors.dob.type === "required" ? 'Required' : null}
                    </div> : null}
                    {dobError && <span className="invalid-feedback">Date of birth cannot be greater than today.</span>}
                  </div>
                </div>
                <div className="welcome-text">
                  <h2>Security <span>Details!</span></h2>
                  {/* <p>Enter your informations below or login with social account</p> */}
                </div>
                <div className="login-box row mb-4">
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">Password <span>*</span></label>
                    <input type="password" className="form-control" autoComplete="new-password" {...register("password", { required: true })} placeholder="Password" />
                    {errors?.password ? <div className="invalid-feedback">
                      {errors.password.type === "required" ? 'Required' : null}
                    </div> : null}
                  </div>
                  <div className="mb-3 col-sm-6">
                    <label htmlFor="" className="form-label">Confirm Password <span>*</span></label>
                    <input
                      type="password"
                      className="form-control"
                      {...register("c_password", {
                        required: true, validate: (value: any) => {
                          let { password } = getValues();
                          return password === value;
                        }
                      })}
                      placeholder="Confirm Password"
                    />
                    {errors?.c_password ? <div className="invalid-feedback">
                      {errors.c_password.type === "required" ? 'Required' : null}
                      {errors.c_password.type === "validate" ? 'Passwords should match!' : null}
                    </div> : null}
                  </div>
                </div>

                <div className="mb-3">
                  <Button className="btn btn-primary me-2" type="submit">Sign up</Button>
                  <Link to="/login" className="btn btn-outline-secondary">Back to login</Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}
import React, { useState, useEffect } from "react";
import { Col, Nav, Row } from "react-bootstrap";
import Header from "../components/header";
import Nobuyreport from "../components/reports/nobuyreport";
import LeftPanel from "../components/leftPanel";
import toast from 'react-hot-toast';
import Tab from 'react-bootstrap/Tab';
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useInterestApi } from "../_common/hooks/api/InterestApiHook";
import { GrPowerReset } from 'react-icons/gr'
import { Link } from "react-router-dom";
import { URLS } from "../_config";
import InvestmentList from "../components/investmentProfile/investmentReport";
import { formatNumber } from "../_common/functions";
import moment from "moment";
import ReactDatePicker from "react-datepicker";


export default function InvestmentProfile() {

  const interestApi = useInterestApi();
  const { showLoader, hideLoader } = useAppLoader();

  const [tableDetails, setTableDetails] = useState<any>(null);
  const [monthlyList, setMonthlyList] = useState<any>(null);
  const [details, setDetails] = useState<any>(null);
  const [filterDate, setFilterDate] = useState<any>(null);
  const [leftSideInterestDetails, setLeftSideInterestDetails] = useState<any>(null);
  const [isShowDailyList, setIsShowDailyList] = useState<boolean>(true)

  const getUserInvestmentReport = () => {
    showLoader();
    interestApi.getUserInvestmentReport({}, (message: string, resp: any) => {
      // console.log({ resp });
      hideLoader();
      setDetails(resp?.data);
      // setMonthlyList(resp?.data?.month_wise_interest);
      const tempList = resp?.data?.month_interest_by_user;
      if (tempList && tempList.length > 0) {
        tempList.sort((a: any, b: any) => {
          if (a.user_certificate_number < b.user_certificate_number) {
            return -1;
          } else if (a.user_certificate_number > b.user_certificate_number) {
            return 1;
          } else {
            return 0;
          }
        });
        setMonthlyList(tempList);
      }
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  const clearFilterDate = () => {
    let filterDateInput: any = document.getElementById("filterDateInput");
    filterDateInput.value = "";
    setFilterDate('')
  }

  const groupByType = (data: any) => {
    return data.reduce((result: any, item: any) => {
      if (!result[item.user_certificate_number]) {
        result[item.user_certificate_number] = [];
      }
      result[item.user_certificate_number].push(item);
      return result;
    }, {});
  }

  const totalInterest = (data: any) => {
    return data.reduce((result: any, item: any) => result + +item.total_interest, 0)
  }

  const totalValuation = (data: any) => {
    return (data.reduce((result: any, item: any) => result + +item.total_interest, 0) + data.reduce((result: any, item: any) => result + +item.certificate_amt, 0))
  }

  useEffect(() => {
    getUserInvestmentReport();
  }, []);

  useEffect(() => {
    if (details && details.data && details.data.length > 0) {
      const tempDetails = groupByType(details.data);
      let groupDetails = Object.entries(tempDetails);
      setTableDetails(groupDetails)
      // console.log(groupDetails)
      // console.log(tempDetails)
    }
  }, [details])

  useEffect(() => {
    if (monthlyList && monthlyList.length > 0) {
      let grouped_interest: any = {};
      monthlyList.forEach((interest: any) => {
        let user_certificate_number = interest.user_certificate_number;
        let calculated_total_interest = interest.total_interest;
        let purchase_date = interest.purchase_date;
        let lock_date = interest.lock_date;

        if (!grouped_interest[user_certificate_number]) {
          grouped_interest[user_certificate_number] = {
            user_certificate_number,
            calculated_total_interest,
            purchase_date,
            lock_date
          };
        } else {
          grouped_interest[user_certificate_number].calculated_total_interest += calculated_total_interest;
        }
      });

      let new_array = Object.values(grouped_interest);

      setLeftSideInterestDetails(new_array);
      // console.log(new_array);
    }
  }, [monthlyList])

  return (
    <React.Fragment>
      <div className="circle-bg"></div>
      <Header />
      <LeftPanel />
      <div className="right-menu-part">
        <div className="dash-body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-12 col-12">
                <div className="baleance-box">
                  <div className="baleance-box-text">
                    <h3>Total Portfolio</h3>
                    <h2>R {formatNumber(details?.user_details?.total_portfolio) || 0}</h2>
                  </div>
                  <div className="baleance-box-text">
                    <h3>Total Certificate</h3>
                    <h2>{details?.user_details?.total_certificate || 0}</h2>
                  </div>
                  <div className="baleance-box-text">
                    <h3>Total Interest</h3>
                    <h2>R {formatNumber(details?.user_details?.total_interest) || 0}</h2>
                  </div>
                  <div className="portfolio-btn">
                    <Link to={URLS.HOME} className="btn ">Back</Link>
                  </div>
                </div>
              </div>
              {monthlyList && monthlyList.length > 0 ?
                <Tab.Container id="left-tabs-example" defaultActiveKey={monthlyList[0]?.user_certificate_number}>
                  <Row className="mobile_fix_ord">
                    <Col sm={4}>
                      <div className="baleance-tab"
                      >
                        <div className="custom-scroll pe-2"
                          style={{ maxHeight: "460px", overflow: "auto" }}
                        >
                          <Nav variant="pills" className="flex-column mb-3">
                            {leftSideInterestDetails && leftSideInterestDetails.length > 0 &&
                              leftSideInterestDetails.map((item: any, index: number) => (
                                <Nav.Item key={index}>
                                  <Nav.Link eventKey={item?.user_certificate_number}>
                                    <div className="in-btn">
                                      {/* <div className="in-img">
                                        <img src="./assets/img/b1.svg" alt="#" />
                                      </div> */}
                                      <div
                                      // onClick={() => { setIsShowDailyList(true) }}
                                      >
                                        <span>Certificate No: {item?.user_certificate_number}</span>
                                        <span>Total Int: R {formatNumber(item?.calculated_total_interest)}</span>
                                        <span>Total Val: R {formatNumber(details?.certificate?.total_valuation)}</span>
                                        <span>Purchased Dt: {moment(item?.purchase_date).format("DD/MM/YYYY")}</span>
                                        <span>Locked Till: {moment(item?.lock_date).format("DD/MM/YYYY")}</span>
                                        <span>Current Interest Rate: {details?.certificate?.["current rate of interest"]}%</span>
                                      </div>
                                    </div>
                                  </Nav.Link>
                                </Nav.Item>
                              ))
                            }
                          </Nav>
                        </div>
                      </div>
                    </Col>
                    <Col sm={8}>
                      <table className="table table-head">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "50%" }}>Total Interest</th>
                            <th scope="col">Interest Year/Month</th>
                          </tr>
                        </thead>
                      </table>
                      <Tab.Content>
                        {monthlyList && monthlyList.length > 0 &&
                          monthlyList.map((item: any, index: number) => (
                            <Tab.Pane
                              eventKey={item["user_certificate_number"]}
                              key={index}>
                              <div className="order-table monthly-int-tbl">
                                <div className="scroll-table custom-scroll">
                                  <table className="table">
                                    {/* <thead>
                                      <tr>
                                        <th scope="col">Total Interest</th>
                                        <th scope="col">Interest Year/Month</th>
                                      </tr>
                                    </thead> */}
                                    <tbody>
                                      <tr key={index}>
                                        <td style={{ width: "50%" }}>R {item?.total_interest}</td>
                                        <td>{item?.interest_month_year}</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Tab.Pane>
                          ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
                :
                <h1 className="text2 text-center py-5">No investment to show</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}
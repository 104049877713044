import React, { useEffect, useState } from "react";
import { formatDate, formatNumber } from '../../_common/functions';

interface incomingProps {
    buyreports: any[];
    user_details: any;
    filterDate: string;
}

export default function BuyList({ buyreports, user_details, filterDate }: incomingProps) {

    // const total = (buyreports.reduce((total, currentItem) => total = total + currentItem?.amount, 0));
    const [totalAmount, setTotalAmount] = useState<number>(0)

    useEffect(() => {
        if (filterDate !== '') {
            const total = buyreports.filter((item, i) =>
                formatDate(item?.created_at).search(filterDate.toLocaleLowerCase()) >= 0)
                .reduce((total, currentItem) => total = total + currentItem?.amount, 0)
            setTotalAmount(total)
        } else {
            const total = (buyreports.reduce((total, currentItem) => total = total + currentItem?.amount, 0));
            setTotalAmount(total)
        }
    }, [filterDate])

    return (
        <React.Fragment>
            {
                buyreports && buyreports.length > 0 ?
                    buyreports.map((item, i) => {
                        if (filterDate !== '') {
                            if (formatDate(item?.created_at).search(filterDate.toLocaleLowerCase()) >= 0) {
                                return (
                                    <tr key={i}>
                                        <td >{user_details?.first_name} {user_details?.last_name}</td>
                                        <td>{formatDate(item?.created_at)}</td>
                                        <td>R {formatNumber(item?.unit_price)}</td>
                                        <td>{formatNumber(item?.quantiy)}</td>
                                        <td>R {formatNumber(item?.amount)}</td>
                                    </tr>
                                )
                            }
                            // else {
                            //     return (
                            //         <tr>
                            //             <td align="center" colSpan={5}>No records Found</td>
                            //         </tr>
                            //     )
                            // }
                        } else {
                            return (
                                <tr key={i}>
                                    <td >{user_details?.first_name} {user_details?.last_name}</td>
                                    <td>{formatDate(item?.created_at)}</td>
                                    <td>R {formatNumber(item?.unit_price)}</td>
                                    <td>{formatNumber(item?.quantiy)}</td>
                                    <td>R {formatNumber(item?.amount)}</td>
                                </tr>
                            )
                        }
                    })
                    : null
            }
            <tr className="total-order">
                <td colSpan={3}></td>
                <td ><b>Total Amount</b></td>
                <td><b>R {formatNumber(totalAmount)}</b></td>
            </tr>
        </React.Fragment>
    )
}



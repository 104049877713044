import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function useCertificateApi() {

  const callApi = useApiCall()

  const userSellCertificate = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.CERTIFICATE.USER_SELL_CERTIFICATE, data, onSuccess, onError);
  }

  return {
    userSellCertificate: userSellCertificate,
  }
}
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useUserSelector } from "../_common/hooks/selectors/userSelector";
import { ACTIONS, URLS } from "../_config";
import '../styles/components/header.scss';
import { store } from "../store";
import { useSidebarSelector } from "../_common/hooks/selectors/loaderSelector";

type BackMode = {
	pageName: string;
	backUrl: string;
}

interface incomingProps {
	backMode?: BackMode
}

export default function Header({ backMode }: incomingProps) {
	const userApi = useUserApi();
	const user: any = useUserSelector()
	const navigate = useNavigate();
	const sidebar: boolean = useSidebarSelector();

	const logout = (e: any) => {
		//	console.log("logout")
		e.preventDefault();
		e.stopPropagation();
		userApi.logout({}, (message: string, resp: any) => {
		}, (message: string) => {
		})
	}

	const updateProfile = () => {
		navigate(URLS.PROFILE)
	}

	const togglesidebar = () => {
		store.dispatch({
			type: ACTIONS.LOADER.SET_SIDEBAR,
			payload: {
				sidebar: !sidebar
			}
		});
	}

	useEffect(() => {
		store.dispatch({
			type: ACTIONS.LOADER.SET_SIDEBAR,
			payload: {
				sidebar: true
			}
		});
		//console.log('USER ->',user)
	}, []);

	return (
		<React.Fragment>
			<div className="top-menu-part">
				<div className="logo-part">
					<Link to="/"><img src="./assets/img/logo.png" alt="#" /></Link>
				</div>
				<div className="project-hambergar" onClick={togglesidebar}><i className="fa fa-bars" aria-hidden="true"></i></div>
				<div className="user-part">
					{/* <div className="notify">
						<a href="#" className="bell-ico"><img src="../assets/img/bell.svg" alt="#" /></a>
						<a href="#"><img src="./assets/img/wallet.svg" alt="#" /></a>
					</div> */}
					<div className="user-login">
						<div className="lo-img">
							<Link to={URLS.PROFILE}><img src={user?.avatar?.original} alt="#" /></Link>
						</div>
						<div className="lo-text">
							<h3>Welcome back</h3>
							<h4>{user?.full_name}</h4>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
}

import { combineReducers } from 'redux';

import loaderReducer from './common/loaderReducer'
import companyReducer from './company/companyReducer';
import userReducer from './user/userReducer'

const rootReducer = combineReducers({
  loader: loaderReducer,
  user: userReducer,
  company: companyReducer,
})

export default rootReducer
import { takeLatest, all, takeEvery } from 'redux-saga/effects'; // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config';


import * as commonSaga from './commonSaga';
import * as userSaga from './userSaga';
import * as companySaga from './companySaga';
import * as certificateSaga from './certificateSaga';
import * as paymentSaga from './paymentSaga';
import * as interestSaga from './interestSaga';


export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.COMMON.CONTACT_US, commonSaga.contactUs),

    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeEvery(SAGA_ACTIONS.USER.REGISTER, userSaga.registeruser),
    takeEvery(SAGA_ACTIONS.USER.REGISTRATION_SUCESSFUL, userSaga.registersucessful),
    takeEvery(SAGA_ACTIONS.USER.PROFILE_UPDATE, userSaga.profileUpdate),
    takeEvery(SAGA_ACTIONS.USER.GET_BENIFITS, userSaga.getbenefits),
    takeEvery(SAGA_ACTIONS.USER.AVATAR_UPLOAD, userSaga.avatarUpload),
    takeEvery(SAGA_ACTIONS.USER.GET_DETAILS, userSaga.getDetails),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),

    takeLatest(SAGA_ACTIONS.CERTIFICATE.USER_SELL_CERTIFICATE, certificateSaga.useSellCertificate),

    takeEvery(SAGA_ACTIONS.PAYMENT.GET_TRANSACTION, paymentSaga.getTransaction),
    takeLatest(SAGA_ACTIONS.PAYMENT.CREATE_TRANSACTION, paymentSaga.createTransaction),
    takeEvery(SAGA_ACTIONS.PAYMENT.GET_USER_TRANSACTION, paymentSaga.getUserTransaction),

    takeEvery(SAGA_ACTIONS.COMPANY.GET_COMPANY_CERTIFICATE, companySaga.getCompanyCertificate),
    takeEvery(SAGA_ACTIONS.COMPANY.GET_COMPANY_LIST, companySaga.getCompanyList),
    takeEvery(SAGA_ACTIONS.COMPANY.COMPANY_DETAILS, companySaga.getCompanyDetails),
    takeEvery(SAGA_ACTIONS.COMPANY.OURGROUP_COMPANY_DETAILS, companySaga.getOurgroupCompanyDetails),

    takeEvery(SAGA_ACTIONS.INTEREST.GET_USER_MONTHLY_INTEREST, interestSaga.getUserMonthlyInterest),
    takeEvery(SAGA_ACTIONS.INTEREST.GET_USER_PROFIT_SHARE, interestSaga.getUserProfitShare),
    takeEvery(SAGA_ACTIONS.INTEREST.GET_USER_DAILY_INTEREST_REPORT, interestSaga.getUserInvestmentReport),
  ]);
}
import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';


export function usePaymentApi() {

  const callApi = useApiCall()

  const getTransaction = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.GET_TRANSACTION, data, onSuccess, onError);
  }

  const createTransaction = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.CREATE_TRANSACTION, data, onSuccess, onError);
  }

  const getUserTracnsaction = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.PAYMENT.GET_USER_TRANSACTION, data, onSuccess, onError);
  }

  return {
    getTransaction: getTransaction,
    createTransaction: createTransaction,
    getUserTracnsaction: getUserTracnsaction,
  }
}
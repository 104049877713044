import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { creditCard, creditCardExp, CheckNumber } from '../../_common/functions';
import toast from 'react-hot-toast';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { usePaymentApi } from '../../_common/hooks/api/PaymentApiHook';

interface props {
	show: boolean;
	close: () => void;
	price: any;
	quantity: any;
}

const BuyCertificateModal = ({ show, close, price, quantity }: props) => {

	const paymentApi = usePaymentApi();
	const { showLoader, hideLoader } = useAppLoader()
	const { register, handleSubmit, control, formState: { errors }, watch, reset } = useForm({ mode: "onChange" });

	const onSubmit = (data: any) => {
		// console.log("Form data", data);
		// console.log(data.card_no.replaceAll(' ', ''));
		// console.log(btoa(data.card_no.replaceAll(' ', '')));
		if (Number(quantity) < 1) return toast.error("Quantity must be greater than zero")

		let textexp_date = data.exp_date;
		const myArray = textexp_date.split("/");

		let params: any = {
			"quantity": quantity,
			"card_number": btoa(data.card_no.replaceAll(' ', '')),
			"cvc": btoa(data.cvc_no),
			"expMonth": btoa(myArray[0].replaceAll(' ', '')),
			"expYear": btoa(myArray[1].replaceAll(' ', ''))
		}

		showLoader();
		paymentApi.createTransaction(params, (message: string, resp: any) => {
			// console.log({ resp })
			hideLoader();
			reset();
			if (resp.status > 200) {
				toast.error(resp.data.data.errors)
				close();
			} else {
				toast.success(resp.message);
				close();
			}
		}, (message: string) => { // login error..
			toast.error(message)
			hideLoader();
			close();
			reset();
		});
	}

	return (
		<>
			<Modal className='opencase-modal' show={show} onHide={() => {
				close(); reset();
			}}>
				<Modal.Body className="payment_module">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="create-case-modal">
							<div className="d-flex flex-column mb-3">
								<input placeholder='Credit card number' className={'form-control ' + (errors?.card_no ? 'invalid' : '')} {...register("card_no", { required: true, minLength: 11, maxLength: 19 })} onChange={creditCard} />
								{errors?.card_no ? <div className="invalid-feedback">
									{errors.card_no.type === "required" ? 'Required' : null}
									{errors.card_no.type === "minLength" ? 'Invalid card number' : null}
									{errors.card_no.type === "maxLength" ? 'Invalid card number' : null}
								</div>
									: null
								}
							</div>
							<div className="d-flex justify-content-between gap-3">
								<div className="d-flex flex-column mb-3">
									<input placeholder='Expiration date' className={'form-control ' + (errors?.exp_date ? 'invalid' : '')} {...register("exp_date", { required: true, minLength: 7, maxLength: 7 })} onChange={creditCardExp} />
									{errors?.exp_date ? <div className="invalid-feedback">
										{errors.exp_date.type === "required" ? 'Required' : null}
										{errors.exp_date.type === "minLength" ? 'Invalid expiry date' : null}
										{errors.exp_date.type === "maxLength" ? 'Invalid expiry date' : null}
									</div>
										: null
									}
								</div>
								<div className="d-flex flex-column mb-3">
									<input type={"password"} placeholder='Security code' className={'form-control ' + (errors?.cvc_no ? 'invalid' : '')} {...register("cvc_no", { required: true, minLength: 3, maxLength: 3 })} onChange={CheckNumber} />
									{errors?.cvc_no ? <div className="invalid-feedback">
										{errors.cvc_no.type === "required" ? 'Required' : null}
										{errors.cvc_no.type === "minLength" ? 'Invalid cvv number' : null}
										{errors.cvc_no.type === "maxLength" ? 'Invalid cvv number' : null}
									</div>
										: null
									}
								</div>
							</div>
							<div>
								<button className="btn btn-primary w-100" type='submit'>Submit</button>
							</div>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default BuyCertificateModal;
import React, { useState, useEffect, useMemo } from "react";
import { Button, Col, Nav, Row } from "react-bootstrap";
import Header from "../components/header";
import BuyList from "../components/reports/buyreport";
import InterestList from "../components/reports/intestreport";
import DividenedList from "../components/reports/dividenedreport";
import Nobuyreport from "../components/reports/nobuyreport";
import LeftPanel from "../components/leftPanel";
import toast from 'react-hot-toast';
import Tab from 'react-bootstrap/Tab';
import { useAppLoader } from "../_common/hooks/common/appLoaderHook";
import { useInterestApi } from "../_common/hooks/api/InterestApiHook";
import { usePaymentApi } from "../_common/hooks/api/PaymentApiHook";
import { GrPowerReset } from 'react-icons/gr'


export default function Report() {

  const paymentApi = usePaymentApi();
  const interestApi = useInterestApi();
  const { showLoader, hideLoader } = useAppLoader();

  const [allTransactions, setTransactions] = useState<any>(null);
  const [allInterests, setAllIntestest] = useState<any>(null);
  const [allDividended, setAllDividended] = useState<any>(null);
  const [filterDate, setFilterDate] = useState<string>('');
  const [selectedCertificate, setSelectedCertificate] = useState<string>('');

  const getTransaction = () => {
    showLoader();
    paymentApi.getUserTracnsaction({}, (message: string, resp: any) => {
      //  console.log({resp});
      hideLoader();
      setTransactions(resp?.data);
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  const getMonthlyInterest = () => {
    showLoader();
    // interestApi.getUserMonthlyInterest({}, (message: string, resp: any) => {
    //   // console.log({ resp });
    //   hideLoader();
    //   setAllIntestest(resp?.data);
    // }, (message: string) => {
    //   toast.error(message)
    //   hideLoader();
    // });
    interestApi.getUserInvestmentReport({}, (message: string, resp: any) => {
      // console.log({ resp });
      hideLoader();
      setAllIntestest(resp?.data?.month_interest_by_user);
      // setAllIntestest(resp?.data?.month_wise_interest);
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  const getUserProfitShare = () => {
    showLoader();
    interestApi.getUserProfitShare({}, (message: string, resp: any) => {
      // console.log({ resp });
      hideLoader();
      setAllDividended(resp?.data);
    }, (message: string) => {
      toast.error(message)
      hideLoader();
    });
  }

  const clearFilterDate = () => {
    let filterDateInput: any = document.getElementById("filterDateInput");
    filterDateInput.value = "";
    setFilterDate('')
  }

  const certfNumOption = useMemo(() => {
    if (allInterests && allInterests.length > 0) {
      let checkList: any = []
      allInterests.forEach((item: any) => {
        if (!checkList.includes(item?.user_certificate_number)) {
          checkList.push(item?.user_certificate_number)
        }
      })
      return checkList
    }
  }, [allInterests])

  const handleCertificateChange = (event: any) => {
    setSelectedCertificate(event.target.value)
    console.log(event.target.value)
  }

  useEffect(() => {
    getTransaction();
    getMonthlyInterest();
    getUserProfitShare();
  }, []);

  return (
    <React.Fragment>
      <div className="circle-bg"></div>
      <Header />
      <LeftPanel />
      <div className="right-menu-part">
        <div className="dash-body">
          <div className="container-fluid">
            <div className="row">
              {/* <div className="col-sm-12 col-12">
                <div className="baleance-box">
                  <div className="baleance-box-text">
                    <h3>Total Balance</h3>
                    <h2>$2356.25</h2>
                  </div>
                  <div className="portfolio-btn">
                    <button type="button" className="btn ">Add Money</button>
                  </div>
                </div>
              </div> */}
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="mobile_fix_ord">
                  <Col sm={4}>
                    <div className="baleance-tab">
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link eventKey="first"><div className="in-btn">
                            <div className="in-img"><img src="./assets/img/b1.svg" alt="#" /></div>
                            <div>
                              <span>Buy report</span>
                            </div>
                          </div></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            <div className="in-btn">
                              <div className="in-img"><img src="./assets/img/b2.svg" alt="#" /></div>
                              <div>
                                <span>Sell report</span>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="Third">
                            <div className="in-btn">
                              <div className="in-img"><img src="./assets/img/b3.svg" alt="#" /></div>
                              <div>
                                <span>Dividend report</span>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="fourth">
                            <div className="in-btn">
                              <div className="in-img"><img src="./assets/img/b4.svg" alt="#" /></div>
                              <div>
                                <span>Interest on investment report</span>
                              </div>
                            </div>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                    </div>
                  </Col>
                  <Col sm={8}>
                    <Tab.Content>
                      <div className="align-items-center d-flex gap-2 justify-content-end">
                        <input type="date" id="filterDateInput" className="form-control w-25" onChange={(e: any) => { setFilterDate(e.target.value) }} defaultValue={filterDate} />
                        <button className="btn btn-primary py-2 rounded-2">
                          <GrPowerReset onClick={() => clearFilterDate()} className="color-white" />
                        </button>
                      </div>
                      <Tab.Pane eventKey="first">
                        <div className="order-table">
                          <h2>Buy report</h2>
                          <div className="scroll-table custom-scroll">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Unit Price</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allTransactions?.transactionData && allTransactions?.transactionData.length > 0 ?
                                  <BuyList
                                    buyreports={allTransactions?.transactionData}
                                    user_details={allTransactions?.user_details}
                                    filterDate={filterDate}
                                  />
                                  :
                                  <Nobuyreport />
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <div className="order-table">
                          <h2>Sell report</h2>
                          <div className="scroll-table custom-scroll">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Title</th>
                                  <th scope="col">Unit Price</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Total</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td align="center" colSpan={4}>No records Found</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Third">
                        <div className="order-table">
                          <h2>Dividend report</h2>
                          <div className="scroll-table custom-scroll">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Name</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Certificate amount</th>
                                  <th scope="col">Quantity</th>
                                  <th scope="col">Dividend</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allDividended?.profitShareData && allDividended?.profitShareData.length > 0 ?
                                  <DividenedList
                                    dividened={allDividended?.profitShareData}
                                    user_details={allDividended?.user_details}
                                    filterDate={filterDate}
                                  />
                                  :
                                  <Nobuyreport />
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <div className="order-table">
                          <div className="d-flex justify-content-between mb-3 align-items-center pb-3"
                          style={{borderBottom: "1px solid #fff"}}
                          >
                            <h2 className="m-0 border-0">Interest on investment report</h2>
                            <div className="d-flex justify-content-end align-items-center gap-2">
                              <label htmlFor="certfop"
                              style={{
                                whiteSpace: "nowrap"
                              }}
                              >Certificate No: </label>
                              <select
                                value={selectedCertificate}
                                onChange={handleCertificateChange}
                                className="form-control h-25 one w-75"
                                style={{ appearance: "revert" }}
                                id='certfop'
                              >
                                <option value="">Certificate No</option>
                                {certfNumOption?.map((option: any) => (
                                  <option key={Math.random()} value={option}>{option}</option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="scroll-table custom-scroll">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th scope="col">Certificate Number</th>
                                  {/* <th scope="col">Month/Year</th> */}
                                  <th scope="col">Total  Interest</th>
                                </tr>
                              </thead>
                              <tbody>
                                {allInterests && allInterests.length > 0 ?
                                  <InterestList
                                    interest={allInterests}
                                    // user_details={allInterests?.user_details}
                                    filterDate={filterDate}
                                    filterCertificateNumber={selectedCertificate}
                                  />
                                  :
                                  <Nobuyreport />
                                }
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
import React from "react";
import Header from '../components/header';
import LeftPanel from "../components/leftPanel";
import PortfolioValue from "../components/dashboard/PortfolioValue";
import InvestmentArea from "../components/dashboard/InvestmentArea";
import { Link } from "react-router-dom";
import { URLS } from "../_config";
import CompanyPortfolio from "../components/dashboard/CompanyPortfolio";

export default function Dashboard() {
    return (
        <React.Fragment>
            <div className="circle-bg"></div>
            <Header />
            <LeftPanel />
            <section className="main-body">
                <div className="right-menu-part">
                    <div className="dash-body">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12 col-12">
                                    <PortfolioValue />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-12">
                                    <CompanyPortfolio />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-12">
                                    <InvestmentArea />
                                </div>

                                <div className="col-lg-4 col-sm-12 col-12">
                                    <div className="investor h-230 text-adj">
                                        <div className="d-flex justify-content-between w-100">
                                            <div className="investor-text">
                                                <h2>First-time Investor?</h2>
                                                <h3>know your benefits</h3>
                                            </div>
                                            <div className="investor-img"><img src="./assets/img/money-up.svg" alt="#" /></div>
                                        </div>
                                        <div className="portfolio-btn">
                                            <Link to={"/benefits"} className="btn btn2">Get Benefits</Link>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-8 col-sm-12 col-12">
                                    <div className="row">
                                        <div className="col-lg-6 col-sm-12 col-12">
                                            <div className="trans-box h-230 text-adj">
                                                <div className="trans-box-text">
                                                    <h2>Check Your
                                                        <span>Transaction</span></h2>

                                                    <img src="./assets/img/money.svg" alt="#" />
                                                </div>
                                                <div className="trans-box-text trans-box-text-two">
                                                    <p>Monitor your Investment & dividend receipt over the period of time. </p>

                                                </div>
                                                <div className="portfolio-btn">
                                                    <Link to={URLS.REPORT} className="btn btn2">All Transactions</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-sm-12 col-12">
                                            <div className="trans-box h-230 text-adj">
                                                <div className="trans-box-text">
                                                    <h2>Message to Our
                                                        <span>Experts</span></h2>

                                                    <img src="./assets/img/robot.svg" alt="#" />
                                                </div>
                                                <div className="trans-box-text trans-box-text-two">
                                                    <p>Message to our expert if you have any doubt.</p>

                                                </div>
                                                <div className="portfolio-btn">
                                                    <Link to={URLS.CONTACT_US} className="btn btn2">Message Now</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}
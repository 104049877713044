import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE, UPDATED_USER } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;


export function* login(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.LOGIN, data);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            sessionStorage.setItem(STORAGE, JSON.stringify(resp.data.data));
            yield put({
                type: ACTIONS.USER.LOGIN,
                payload: {
                    user: resp.data.data.user,
                    token: resp.data.data.token,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* logout(action: any): any {
    try {
        sessionStorage.removeItem(STORAGE)
        sessionStorage.removeItem(UPDATED_USER)
        yield put({
            type: ACTIONS.USER.LOGOUT,
        });
        action && action.callbackSuccess && action.callbackSuccess({});
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registeruser(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTER, data);
        // console.log('Register saga', resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* profileUpdate(action: any): any {
    try {
        const data = action.payload;
        const id = data.id;
        delete data.id;
        const resp = yield call(CallApi.PUT, API_URL.USER.PROFILE_UPDATE + `/${id}`, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* registersucessful(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.REGISTRATION_SUCESSFUL, data);
        // console.log('Register sucessfull saga', resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getbenefits(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.USER.GET_BENIFITS, data, true);
        //console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* avatarUpload(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.AVATAR_UPLOAD, data, true);
        //console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getDetails(action: any): any {
    try {
        const data = action.payload;
        const username = data.username;
        delete data.username
        const resp = yield call(CallApi.GET, API_URL.USER.GET_DETAILS + `/${username}`, {}, true);
        //console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            sessionStorage.setItem(UPDATED_USER, JSON.stringify(resp.data.data));
            yield put({
                type: ACTIONS.USER.GET_DETAILS,
                payload: {
                    user: resp.data.data.details,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* forgotPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.FORGOT_PASSWORD, data);
        //console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* resetPassword(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.USER.RESET_PASSWORD, data);
        //console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useAppLoader } from '../../_common/hooks/common/appLoaderHook';
import { useCertificateApi } from '../../_common/hooks/api/CertificateApiHook';
import { handleKeyPressNumber } from '../../_common/functions';

interface props {
	show: boolean;
	close: () => void;
	maxQty: any;
}

const SellCertificateModal = ({ show, close, maxQty }: props) => {

	const certificateApi = useCertificateApi();
	const { showLoader, hideLoader } = useAppLoader()

	const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onChange" });

	const onSubmit = (data: any) => {
		// console.log("Form data", data);
		if (Number(data.quantity) <= 0) return toast.error("Number of Certificate must be atleast one")

		if (Number(data.quantity) > maxQty) return toast.error("Number of Certificate can't be more than maximum saleable quantity")

		let params: any = {
			"quantity": data.quantity,
		}

		showLoader();
		certificateApi.userSellCertificate(params, (message: string, resp: any) => {
			// console.log({ resp })
			reset();
			hideLoader();
			if (resp.status > 200) {
				toast.error(resp.data.data.errors)
				close();
			} else {
				toast.success(resp.message);
				close();
			}
		}, (message: string) => {
			toast.error(message)
			hideLoader();
			close();
			reset();
		});
	}

	return (
		<>
			<Modal className='opencase-modal' show={show} onHide={() => {
				close(); reset();
			}}>
				<Modal.Body className="payment_module">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="create-case-modal">
							<div className="d-flex align-items-center justify-content-between mb-3">
								<label htmlFor="" className="form-label">Qty </label>
								<input
									defaultValue={1}
									className="form-select form-control"
									{...register("quantity", { required: true })}
									onKeyPress={(e: any) => handleKeyPressNumber(e)}
								/>
							</div>
						</div>
						<div>
							<button className="btn btn-primary w-100" type='submit'>Submit</button>
						</div>
					</form>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default SellCertificateModal;
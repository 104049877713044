//import { getSubdomain } from './functions';
export const API_BASE_URL = process.env.REACT_APP_API_URL;

export const STORAGE = 'ourgroup';
export const UPDATED_USER = 'updatedUser';
export const APP_URL = window.location.origin;

export const URLS = {
  HOME: '/',
  WELCOME: '/welcome',
  LOGIN: '/login',
  SIGNUP: '/signup',
  DASHBOARD: '/dashboard',
  PAYMENTS: '/payments',
  BUY_CERTIFICATE: '/buy-certificate',
  BENIFITS: '/benefits',
  PROFILE: '/profile',
  PROFILE_UPDATE: '/profile-update',
  REPORT: '/report',
  INVESTMENT_PROFILE: '/investment-profile',
  ALL_COMPANIES: '/all-companies',
  CONTACT_US: '/contact-us',
  OURGROUP_COMPANY_DETAILS: '/ourgroup-company-details',
  COMPANY_DETAILS: '/company/:id',
  REGISTRATION_SUCESSFUL: '/registration-successful/:id',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:id',
}


export const API_URL = {
  COMMON: {
    API: `${API_BASE_URL}`,
    CONTACT_US: `${API_BASE_URL}/support-message/add-message`,
  },
  USER: {
    LOGIN: `${API_BASE_URL}/login`,
    REGISTER: `${API_BASE_URL}/registerOurGroup`,
    REGISTRATION_SUCESSFUL: `${API_BASE_URL}/registration-successful`,
    PROFILE_UPDATE: `${API_BASE_URL}/users`,
    GET_BENIFITS: `${API_BASE_URL}/get-benifits`,
    AVATAR_UPLOAD: `${API_BASE_URL}/users/avatar`,
    GET_DETAILS: `${API_BASE_URL}/users`,
    FORGOT_PASSWORD: `${API_BASE_URL}/forgot/password`,
    RESET_PASSWORD: `${API_BASE_URL}/set/password`,
  },
  CERTIFICATE: {
    USER_SELL_CERTIFICATE: `${API_BASE_URL}/certificate/sell-certificate`,
  },
  PAYMENT: {
    GET_TRANSACTION: `${API_BASE_URL}/payment/get-transaction`,
    CREATE_TRANSACTION: `${API_BASE_URL}/payment/create-transaction`,
    GET_USER_TRANSACTION: `${API_BASE_URL}/payment/user-transaction-list`,
  },
  COMPANY: {
    GET_COMPANY_CERTIFICATE: `${API_BASE_URL}/company-certificate`,
    GET_COMPANY_LIST: `${API_BASE_URL}/companyList`,
    COMPANY_DETAILS: `${API_BASE_URL}/company-investment`,
    OURGROUP_COMPANY_DETAILS: `${API_BASE_URL}/ourgroup-company-details`,
  },
  INTEREST: {
    GET_USER_MONTHLY_INTEREST: `${API_BASE_URL}/interest/user-monthly-interst-list`,
    GET_USER_PROFIT_SHARE: `${API_BASE_URL}/interest/user-profit-share-history`,
    GET_USER_DAILY_INTEREST_REPORT: `${API_BASE_URL}/interest/user-daywise-interest-list`,
  }
}

export const ACTIONS = {
  LOADER: {
    SET_FP_STATUS: 'ACTIONS/LOADER/SET_FP_STATUS',
    SET_SIDEBAR: 'ACTIONS/LOADER/SET_SIDEBAR',
  },
  COMMON: {
    CONTACT_US: 'ACTIONS/COMMON/CONTACT_US',
  },
  USER: {
    LOGIN: 'ACTIONS/USER/LOGIN',
    LOGOUT: 'ACTIONS/USER/LOGOUT',
    REGISTER: 'ACTIONS/USER/REGISTER',
    PROFILE_UPDATE: 'ACTIONS/USER/PROFILE_UPDATE',
    GET_BENIFITS: 'ACTIONS/USER/GET_BENIFITS',
    AVATAR_UPLOAD: 'ACTIONS/USER/AVATAR_UPLOAD',
    GET_DETAILS: 'ACTIONS/USER/GET_DETAILS',
  },
  CERTIFICATE: {
    USER_SELL_CERTIFICATE: 'ACTIONS/CERTIFICATE/USER_SELL_CERTIFICATE',
    CREATE_TRANSACTION: 'ACTIONS/CERTIFICATE/CREATE_TRANSACTION',
  },
  PAYMENT: {
    GET_TRANSACTION: 'ACTIONS/PAYMENT/GET_TRANSACTION',
    CREATE_TRANSACTION: 'ACTIONS/PAYMENT/CREATE_TRANSACTION',
    GET_USER_TRANSACTION: 'ACTIONS/PAYMENT/GET_USER_TRANSACTION',
  },
  COMPANY: {
    GET_COMPANY_CERTIFICATE: 'ACTIONS/COMPANY/GET_COMPANY_CERTIFICATE',
    GET_COMPANY_LIST: 'ACTIONS/COMPANY/GET_COMPANY_LIST',
    COMPANY_DETAILS: 'ACTIONS/COMPANY/COMPANY_DETAILS',
    OURGROUP_COMPANY_DETAILS: 'ACTIONS/COMPANY/OURGROUP_COMPANY_DETAILS',
  },
  INTEREST: {
    GET_USER_MONTHLY_INTEREST: 'ACTIONS/INTEREST/GET_USER_MONTHLY_INTEREST',
    GET_USER_PROFIT_SHARE: 'ACTIONS/INTEREST/GET_USER_PROFIT_SHARE',
    GET_USER_DAILY_INTEREST_REPORT: 'ACTIONS/INTEREST/GET_USER_DAILY_INTEREST_REPORT',
  }
}

export const SAGA_ACTIONS = {
  COMMON: {
    CONTACT_US: 'SAGA_ACTIONS/COMMON/CONTACT_US',
  },
  USER: {
    LOGIN: 'SAGA_ACTIONS/USER/LOGIN',
    LOGOUT: 'SAGA_ACTIONS/USER/LOGOUT',
    REGISTER: 'SAGA_ACTIONS/USER/REGISTER',
    REGISTRATION_SUCESSFUL: 'SAGA_ACTIONS/USER/REGISTRATION_SUCESSFUL',
    PROFILE_UPDATE: 'SAGA_ACTIONS/USER/PROFILE_UPDATE',
    GET_BENIFITS: 'SAGA_ACTIONS/USER/GET_BENIFITS',
    AVATAR_UPLOAD: 'SAGA_ACTIONS/USER/AVATAR_UPLOAD',
    GET_DETAILS: 'SAGA_ACTIONS/USER/GET_DETAILS',
    FORGOT_PASSWORD: 'SAGA_ACTIONS/USER/FORGOT_PASSWORD',
    RESET_PASSWORD: 'SAGA_ACTIONS/USER/RESET_PASSWORD',
  },
  CERTIFICATE: {
    USER_SELL_CERTIFICATE: 'SAGA_ACTIONS/CERTIFICATE/USER_SELL_CERTIFICATE',
  },
  PAYMENT: {
    GET_TRANSACTION: 'SAGA_ACTIONS/PAYMENT/GET_TRANSACTION',
    CREATE_TRANSACTION: 'SAGA_ACTIONS/PAYMENT/CREATE_TRANSACTION',
    GET_USER_TRANSACTION: 'SAGA_ACTIONS/PAYMENT/GET_USER_TRANSACTION',
  },
  COMPANY: {
    GET_COMPANY_CERTIFICATE: 'SAGA_ACTIONS/COMPANY/GET_COMPANY_CERTIFICATE',
    GET_COMPANY_LIST: 'SAGA_ACTIONS/COMPANY/GET_COMPANY_LIST',
    COMPANY_DETAILS: 'SAGA_ACTIONS/COMPANY/COMPANY_DETAILS',
    OURGROUP_COMPANY_DETAILS: 'SAGA_ACTIONS/COMPANY/OURGROUP_COMPANY_DETAILS',
  },
  INTEREST: {
    GET_USER_MONTHLY_INTEREST: 'SAGA_ACTIONS/INTEREST/GET_USER_MONTHLY_INTEREST',
    GET_USER_PROFIT_SHARE: 'SAGA_ACTIONS/INTEREST/GET_USER_PROFIT_SHARE',
    GET_USER_DAILY_INTEREST_REPORT: 'SAGA_ACTIONS/INTEREST/GET_USER_DAILY_INTEREST_REPORT',
  }
}
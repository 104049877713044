import { Link, useNavigate } from 'react-router-dom';
import { changeString, extractContent, formatNumber } from '../../_common/functions';

interface incomingProps {
    company: any
}

export default function Company({ company }: incomingProps) {

    const navigate = useNavigate()

    return (
        <div className="col-lg-6 col-md-6 col-12">
            <div className="black-box ">
                <div className="in-blk">
                    <div className="img_blk" onClick={() => navigate(`/company/${company.id}`)} style={{cursor: "pointer"}}>
                        <img src={company?.avatar?.original} alt="" />
                    </div>
                    <div className="bk-head-box" onClick={() => navigate(`/company/${company.id}`)} style={{cursor: "pointer"}}>
                        <h2>{company?.name}</h2>
                    </div>
                    <div className="bk-head-box">
                        <h2 style={{whiteSpace:"nowrap"}}>Profit: R {formatNumber(company?.financial?.last_row?.net_profit)}</h2>
                    </div>
                    <div className="bk-head-box">
                        <h2 style={{whiteSpace:"nowrap"}}>Income: R {formatNumber(company?.financial?.last_row?.total_income)}</h2>
                    </div>
                    {/* <div className="bk-check-box">
                        <img src="./assets/img/approve.png" alt="#" />
                    </div> */}
                </div>
                <div className="bk-para-box">
                    <div className="pr-peragraph description-text">{changeString(extractContent(company?.description), 180)} ...</div>
                </div>
            </div>
        </div>
    )
}
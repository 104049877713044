import { Link } from 'react-router-dom';
import { useUserApi } from "../_common/hooks/api/UserApiHook";
import { useSidebarSelector } from '../_common/hooks/selectors/loaderSelector';

interface incomingProps {
    page?: number;
}

const LeftPanel = ({ page }: incomingProps) => {
    const sidebar: boolean = useSidebarSelector();

    const userApi = useUserApi();

    const path: string = (window as any).location.pathname;

    const logout = (e: any) => {
        //	console.log("logout")
        e.preventDefault();
        e.stopPropagation();
        userApi.logout({}, (message: string, resp: any) => {
        }, (message: string) => {
        })
    }


    return (
        <div className={"left-menu-part " + (sidebar ? "open" : "close")}>
            <ul>
                <li className={path == "/" ? "active" : ''} ><Link to="/"><img src="./assets/img/home.png" alt='' /> Home</Link></li>
                <li className={path == "/report" ? "active" : ''}><Link to="/report"><img src="./assets/img/graph.png" alt='' /> Report</Link></li>
                <li className={path == "/benefits" ? "active" : ''}><Link to="/benefits"><img src="../assets/img/investment.png" alt='' />Benefits</Link></li>
                <li className={path == "/buy-certificate" ? "active" : ''}><Link to="/buy-certificate"><img src="../assets/img/payment.png" alt='' />Buy Certificate</Link></li>
                <li className={path == "/investment-profile" ? "active" : ''}><Link to="/investment-profile"><img src="../assets/img/up-arrow.png" alt='' />Investment Profile</Link></li>
                <li><Link to="" onClick={logout}><img src="./assets/img/logout.png" alt='' /> Logout</Link></li>
            </ul>
        </div>
    )
}

export default LeftPanel
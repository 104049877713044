import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* getUserMonthlyInterest(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.INTEREST.GET_USER_MONTHLY_INTEREST, data, true);
        // console.log('GET TRAnction',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getUserProfitShare(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.INTEREST.GET_USER_PROFIT_SHARE, data, true);
        // console.log('GET TRAnction',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getUserInvestmentReport(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.INTEREST.GET_USER_DAILY_INTEREST_REPORT, data, true);
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
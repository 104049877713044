import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Benefit from "./benefit";

interface incomingProps {
    allBenifits: any[];
}

export default function BenefitList({ allBenifits }: incomingProps) {
    // console.log(allBenifits);

    return (
        <React.Fragment>
            {allBenifits.map((data: any, i: any) => (
                <Benefit data={data} key={i} />

            ))}
        </React.Fragment>
    )
}



import React from "react";
import Header from "../components/header";
import LeftPanel from "../components/leftPanel";
import { useCompanyListSelector } from "../_common/hooks/selectors/companySelector";
import Company from "../components/companies/company";
import NoCompanies from "../components/companies/noCompanies";

export default function AllCompanies() {

  const companyList: any = useCompanyListSelector();

  return (
    <React.Fragment>
      <div className="circle-bg"></div>
      <Header />
      <LeftPanel />
      <section className="main-body">
        <div className="right-menu-part">
          <div className="benifit-sec">
            <h2 className="pr-header">Company Portfolio</h2>
            <p className="pr-peragraph description-text">Our Group invest on the following Companies to get maximum returs.</p>
            <div className="row">
              {companyList && companyList.length > 0 ?
                companyList.map((company: any, index: number) => (
                  <Company company={company} key={index} />
                ))
                :
                <NoCompanies />
              }
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}
import { call, put } from 'redux-saga/effects';
import { ACTIONS, API_URL, STORAGE } from '../../_config'
import { CallApi } from './api/callApi';
const API_BASE_URL = process.env.REACT_APP_API_URL;

export function* getCompanyCertificate(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMPANY.GET_COMPANY_CERTIFICATE, data, true);
        //  console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCompanyList(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.GET, API_URL.COMPANY.GET_COMPANY_LIST, data, true);
        //  console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            yield put({
                type: ACTIONS.COMPANY.GET_COMPANY_LIST,
                payload: {
                    companyList: resp?.data?.data?.companyList,
                }
            })
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getCompanyDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMPANY.COMPANY_DETAILS, data, true);
        //  console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}

export function* getOurgroupCompanyDetails(action: any): any {
    try {
        const data = action.payload;
        const resp = yield call(CallApi.POST, API_URL.COMPANY.OURGROUP_COMPANY_DETAILS, data, true);
        //  console.log('GET BENIFITS',resp)
        if (resp.status >= 200 && resp.status < 300 && resp.data) {
            action && action.callbackSuccess && action.callbackSuccess(resp.data);
        } else {
            action && action.callbackError && action.callbackError(resp.data);
        }
    } catch (e: any) {
        action && action.callbackError && action.callbackError(e && e.data ? e.data : e);
    }
}
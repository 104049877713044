import { SAGA_ACTIONS } from '../../../_config';
import { useApiCall } from '../common/appApiCallHook';
import {
  BlankReq,
  LoginReq,
} from '../../interfaces/ApiReqRes'


export function useInterestApi() {

  const callApi = useApiCall()

  const getUserMonthlyInterest = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.INTEREST.GET_USER_MONTHLY_INTEREST, data, onSuccess, onError);
  }

  const getUserProfitShare = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.INTEREST.GET_USER_PROFIT_SHARE, data, onSuccess, onError);
  }

  const getUserInvestmentReport = (data: any, onSuccess: Function, onError: Function) => {
    callApi(SAGA_ACTIONS.INTEREST.GET_USER_DAILY_INTEREST_REPORT, data, onSuccess, onError);
  }

  return {
    getUserMonthlyInterest: getUserMonthlyInterest,
    getUserProfitShare: getUserProfitShare,
    getUserInvestmentReport: getUserInvestmentReport,
  }
}
import React from "react";
import { Container } from "react-bootstrap";

export default function NoBenefits() {

    return (
        <React.Fragment>
            <div className="no-cases">
                <Container>
                    <h6 className="text2">Do not have any benifits currently</h6>
                </Container>
            </div>
        </React.Fragment>
    )
}